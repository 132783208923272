import { RedocStandalone } from "redoc";
import { redocOptions } from "./Styles/RedocTheme";

export function APIDocumentation() {
  return (
    <RedocStandalone
      specUrl="/api-spec/freightsimple-openapi-spec-v1.yaml"
      options={redocOptions}
    />
  );
}
